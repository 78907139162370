export enum RequestStatus {
  UNCALLED = "UNCALLED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export type SortOrder = "asc" | "desc";

export type ApiErrorCode = number | null;

export enum MimeTypes {
  "doc" = "application/msword",
  "docx" = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "gif" = "image/gif",
  "jpeg" = "image/jpeg",
  "mpeg" = "video/mpeg",
  "pdf" = "application/pdf",
  "ppt" = "application/vnd.ms-powerpoint",
  "pptx" = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "tiff" = "image/tiff",
  "txt" = "text/plain",
  "xls" = "application/vnd.ms-excel",
  "xlsx" = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "zip" = "application/zip",
  "x-zip" = "application/x-zip-compressed",
}
