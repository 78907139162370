import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import useFetchDocumentsList from "hooks/useFetchDocumentsList";
import { hasUserAccessToFeature, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { useSelector } from "react-redux";
import { getUserProfile } from "store/app/app.selectors";
import AdminView from "components/AdminView";
import CustomerView from "components/CustomerView";

const DocumentsPage: React.FC = () => {
  const { t } = useTranslation("documentsPage");
  const userProfile = useSelector(getUserProfile);

  useFetchDocumentsList();

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      {hasUserAccessToFeature(
        userProfile,
        FEATURES.OTHER_DOCUMENTATION,
        [USER_ACCESS.VIEW, USER_ACCESS.CREATE, USER_ACCESS.UPDATE, USER_ACCESS.DELETE],
        true
      ) ? (
        <AdminView />
      ) : (
        <CustomerView />
      )}
    </PageLayout>
  );
};

export default DocumentsPage;
