import * as types from "./documents.types";

export const documentsListRequestAC: types.DocumentsListRequestAC = () => ({
  type: types.DOCUMENTS_LIST_REQUEST,
});

export const documentsListRequestSuccessAC: types.DocumentsListRequestSuccessAC = (payload) => ({
  type: types.DOCUMENTS_LIST_REQUEST_SUCCESS,
  payload,
});

export const documentsListRequestFailureAC: types.DocumentsListRequestFailureAC = () => ({
  type: types.DOCUMENTS_LIST_REQUEST_FAILURE,
});

export const setDocumentsListFiltersAC: types.SetDocumentsListFiltersAC = (payload) => ({
  type: types.SET_DOCUMENTS_LIST_FILTERS,
  payload,
});

export const setDocumentsListSortFieldAC: types.SetDocumentsListSortFieldAC = (payload) => ({
  type: types.SET_DOCUMENTS_LIST_SORT_FIELD,
  payload,
});

export const setDocumentsListPerPageAC: types.SetDocumentsListPerPageAC = (payload) => ({
  type: types.SET_DOCUMENTS_LIST_PER_PAGE,
  payload,
});

export const setDocumentsListCurrentPageAC: types.SetDocumentsListCurrentPageAC = (payload) => ({
  type: types.SET_DOCUMENTS_LIST_CURRENT_PAGE,
  payload,
});

export const addDocumentToListAC: types.AddDocumentToListAC = (payload) => ({
  type: types.ADD_DOCUMENT_TO_LIST,
  payload,
});

export const updateDocumentPermissionsAC: types.UpdateDocumentPermissionsAC = (payload) => ({
  type: types.UPDATE_DOCUMENT_PERMISSIONS,
  payload,
});

export const replaceDocumentInListAC: types.ReplaceDocumentInListAC = (payload) => ({
  type: types.REPLACE_DOCUMENT_IN_LIST,
  payload,
});

export const deleteDocumentFromListAC: types.DeleteDocumentFromListAC = (payload) => ({
  type: types.DELETE_DOCUMENT_FROM_LIST,
  payload,
});
