import { RequestStatus } from "types/common.types";
import {
  DOCUMENTS_LIST_REQUEST,
  DOCUMENTS_LIST_REQUEST_FAILURE,
  DOCUMENTS_LIST_REQUEST_SUCCESS,
  DocumentsListActions,
  DocumentsListStateSlice,
  SET_DOCUMENTS_LIST_FILTERS,
  SET_DOCUMENTS_LIST_CURRENT_PAGE,
  SET_DOCUMENTS_LIST_PER_PAGE,
  SET_DOCUMENTS_LIST_SORT_FIELD,
  ADD_DOCUMENT_TO_LIST,
  UPDATE_DOCUMENT_PERMISSIONS,
  REPLACE_DOCUMENT_IN_LIST,
  DELETE_DOCUMENT_FROM_LIST,
} from "./documents.types";

export const initState: DocumentsListStateSlice = {
  list: [],
  filters: { documentName: "", customerNames: "" },
  requestStatus: RequestStatus.UNCALLED,
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 10,
};

export default function documentsReducer(
  state: DocumentsListStateSlice = initState,
  action: DocumentsListActions
): DocumentsListStateSlice {
  switch (action.type) {
    case DOCUMENTS_LIST_REQUEST: {
      return {
        ...state,
        requestStatus: RequestStatus.PENDING,
      };
    }
    case DOCUMENTS_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        requestStatus: RequestStatus.SUCCESS,
      };
    }
    case DOCUMENTS_LIST_REQUEST_FAILURE: {
      return {
        ...initState,
        requestStatus: RequestStatus.FAILURE,
      };
    }
    case SET_DOCUMENTS_LIST_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case SET_DOCUMENTS_LIST_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "desc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "asc" ? "desc" : "asc",
        currentPage: 1,
      };
    }
    case SET_DOCUMENTS_LIST_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case SET_DOCUMENTS_LIST_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case ADD_DOCUMENT_TO_LIST: {
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    case UPDATE_DOCUMENT_PERMISSIONS: {
      const documentIndex = state.list.findIndex((document) => document.documentId === action.payload.documentId);
      state.list[documentIndex].customerPermissions = action.payload.customerPermissions;
      return {
        ...state,
      };
    }
    case REPLACE_DOCUMENT_IN_LIST: {
      const documentIndex = state.list.findIndex((document) => document.documentId === action.payload.oldDocumentId);
      state.list[documentIndex] = action.payload.newDocument;
      return {
        ...state,
      };
    }
    case DELETE_DOCUMENT_FROM_LIST: {
      return {
        ...state,
        list: state.list.filter((document) => document.documentId !== action.payload),
      };
    }
    default:
      return state;
  }
}
