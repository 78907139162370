import React, { useState } from "react";
import { Button, Modal, Spinner } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { DocumentModel } from "types/documentationApi.types";
import { useDispatch } from "react-redux";
import * as documentsApi from "api/documentation.api";
import { parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";
import { notificationErrorAC } from "store/notification/notification.actions";
import { documentsActions } from "store/documents";

interface DeleteDocumentPopupProps {
  isOpen: boolean;
  fileToDelete: DocumentModel | null;
  setIsOpen: (isOpen: boolean) => void;
  resetEditFile: () => void;
}

const DeleteDocumentPopup = ({ isOpen, fileToDelete, setIsOpen, resetEditFile }: DeleteDocumentPopupProps) => {
  const { t } = useTranslation("documentsPage");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseBtnClick = () => {
    resetEditFile();
    setIsOpen(false);
  };

  const handleReplaceBtnClick = async () => {
    setIsLoading(true);
    if (fileToDelete) {
      try {
        await documentsApi.deleteDocument(fileToDelete.documentId);
        resetEditFile();
        setIsOpen(false);
        dispatch(documentsActions.deleteDocumentFromListAC(fileToDelete.documentId));
      } catch (apiErrorMessage) {
        console.error(apiErrorMessage, "DELETE FILE REQUEST FAILURE");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:deleteFileRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={t("deleteDocumentPopupTitle")}
      open={isOpen}
      controls={
        <>
          <Button
            type="secondary"
            onClick={handleCloseBtnClick}
            disabled={isLoading}
            data-testid="deleteDocumentPopupCancelBtn"
          >
            {t("common:Cancel")}
          </Button>
          <Button
            onClick={handleReplaceBtnClick}
            loading={isLoading}
            disabled={isLoading}
            data-testid="deleteDocumentPopupDeleteBtn"
          >
            {t("common:Delete")}
          </Button>
        </>
      }
      onCloseBtnClick={handleCloseBtnClick}
      data-testid="deleteDocumentPopup"
    >
      <Spinner show={isLoading} />
      {t("deleteDocumentPopupMsgBegin")}
      <b>{fileToDelete?.documentName}</b>
      {t("deleteDocumentPopupMsgEnd")}
    </Modal>
  );
};
export default DeleteDocumentPopup;
