import { AppThunk } from "store";
import * as documentsApi from "api/documentation.api";
import * as documentsActions from "./documents.actions";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";

export const getDocumentsList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(documentsActions.documentsListRequestAC());
    const { data } = await documentsApi.getDocuments();
    dispatch(documentsActions.documentsListRequestSuccessAC(data.documents));
  } catch (apiErrorMessage) {
    console.error(apiErrorMessage, "DOCUMENTS LIST REQUEST FAILURE");
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:documentsListRequestFailure"),
        description: parseApiErrorMessage(apiErrorMessage),
      })
    );
    dispatch(documentsActions.documentsListRequestFailureAC());
  }
};
