const config = {
  baseUrl: String((window as any).BASE_URL),
  widgetHost: String((window as any).WIDGET_HOST),
  authWebUrl: String((window as any).AUTH_WEB_URL),
  authApiUrl: String((window as any).AUTH_API_URL),
  documentationApiUrl: String((window as any).DOCUMENTATION_API_URL),
  previewDocumentSecret: String((window as any).PREVIEW_DOCUMENT_SECRET),
};

export default config;
