import { RequestStatus, SortOrder } from "types/common.types";
import { CustomerModel, DocumentModel } from "types/documentationApi.types";

export interface DocumentsFiltersModel {
  documentName: string;
  customerNames: string;
}

export interface DocumentsListStateSlice {
  readonly list: DocumentModel[];
  readonly filters: DocumentsFiltersModel;
  readonly requestStatus: RequestStatus;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrder;
  readonly currentPage: number;
  readonly perPage: number;
}

export type SortFieldType = keyof DocumentModel | "";

export const DOCUMENTS_LIST_REQUEST = "DOCUMENTS_LIST_REQUEST";
export type DocumentsListRequestAction = { type: typeof DOCUMENTS_LIST_REQUEST };
export type DocumentsListRequestAC = () => DocumentsListRequestAction;

export const DOCUMENTS_LIST_REQUEST_SUCCESS = "DOCUMENTS_LIST_REQUEST_SUCCESS";
export type DocumentsListRequestSuccessAction = {
  type: typeof DOCUMENTS_LIST_REQUEST_SUCCESS;
  payload: DocumentModel[];
};
export type DocumentsListRequestSuccessAC = (payload: DocumentModel[]) => DocumentsListRequestSuccessAction;

export const DOCUMENTS_LIST_REQUEST_FAILURE = "DOCUMENTS_LIST_REQUEST_FAILURE";
export type DocumentsListRequestFailureAction = { type: typeof DOCUMENTS_LIST_REQUEST_FAILURE };
export type DocumentsListRequestFailureAC = () => DocumentsListRequestFailureAction;

export const SET_DOCUMENTS_LIST_FILTERS = "SET_DOCUMENTS_LIST_FILTERS";
export type SetDocumentsListFiltersAction = {
  type: typeof SET_DOCUMENTS_LIST_FILTERS;
  payload: DocumentsFiltersModel;
};
export type SetDocumentsListFiltersAC = (payload: DocumentsFiltersModel) => SetDocumentsListFiltersAction;

export const SET_DOCUMENTS_LIST_SORT_FIELD = "SET_DOCUMENTS_LIST_SORT_FIELD";
export type SetDocumentsListSortFieldAction = {
  type: typeof SET_DOCUMENTS_LIST_SORT_FIELD;
  payload: SortFieldType;
};
export type SetDocumentsListSortFieldAC = (payload: SortFieldType) => SetDocumentsListSortFieldAction;

export const SET_DOCUMENTS_LIST_PER_PAGE = "SET_DOCUMENTS_LIST_PER_PAGE";
export type SetDocumentsListPerPageAction = {
  type: typeof SET_DOCUMENTS_LIST_PER_PAGE;
  payload: number;
};
export type SetDocumentsListPerPageAC = (payload: number) => SetDocumentsListPerPageAction;

export const SET_DOCUMENTS_LIST_CURRENT_PAGE = "SET_DOCUMENTS_LIST_CURRENT_PAGE";
export type SetDocumentsListCurrentPageAction = {
  type: typeof SET_DOCUMENTS_LIST_CURRENT_PAGE;
  payload: number;
};
export type SetDocumentsListCurrentPageAC = (payload: number) => SetDocumentsListCurrentPageAction;

export const ADD_DOCUMENT_TO_LIST = "ADD_DOCUMENT_TO_LIST";
export type AddDocumentToListAction = {
  type: typeof ADD_DOCUMENT_TO_LIST;
  payload: DocumentModel;
};
export type AddDocumentToListAC = (payload: DocumentModel) => AddDocumentToListAction;

export const UPDATE_DOCUMENT_PERMISSIONS = "UPDATE_DOCUMENT_PERMISSIONS";
export type UpdateDocumentPermissionsAction = {
  type: typeof UPDATE_DOCUMENT_PERMISSIONS;
  payload: { documentId: number; customerPermissions: CustomerModel[] };
};
export type UpdateDocumentPermissionsAC = (payload: {
  documentId: number;
  customerPermissions: CustomerModel[];
}) => UpdateDocumentPermissionsAction;

export const REPLACE_DOCUMENT_IN_LIST = "REPLACE_DOCUMENT_IN_LIST";
export type ReplaceDocumentInListAction = {
  type: typeof REPLACE_DOCUMENT_IN_LIST;
  payload: {
    oldDocumentId: number;
    newDocument: DocumentModel;
  };
};
export type ReplaceDocumentInListAC = (payload: {
  oldDocumentId: number;
  newDocument: DocumentModel;
}) => ReplaceDocumentInListAction;

export const DELETE_DOCUMENT_FROM_LIST = "DELETE_DOCUMENT_FROM_LIST";
export type DeleteDocumentFromListAction = {
  type: typeof DELETE_DOCUMENT_FROM_LIST;
  payload: number;
};
export type DeleteDocumentFromListAC = (payload: number) => DeleteDocumentFromListAction;

export type DocumentsListActions =
  | DocumentsListRequestAction
  | DocumentsListRequestSuccessAction
  | DocumentsListRequestFailureAction
  | SetDocumentsListFiltersAction
  | SetDocumentsListSortFieldAction
  | SetDocumentsListPerPageAction
  | SetDocumentsListCurrentPageAction
  | AddDocumentToListAction
  | UpdateDocumentPermissionsAction
  | ReplaceDocumentInListAction
  | DeleteDocumentFromListAction;
