import React from "react";
import { Router, RouterProps } from "react-router-dom";
import AppRouter from "router/AppRouter";
import { SnackbarProvider } from "notistack";
import i18n, { Provider as I18nProvider } from "./locales";
import { Provider as StoreProvider } from "react-redux";
import store from "./store";
import SpinnerRenderer from "./containers/Renderer/SpinnerRenderer";
import Notification from "containers/Notification";

interface AppProps {
  history: RouterProps["history"];
}

const App: React.FC<AppProps> = ({ history }) => (
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <StoreProvider store={store}>
        <Router history={history}>
          <SnackbarProvider maxSnack={5}>
            <SpinnerRenderer>
              <AppRouter />
              <Notification />
            </SpinnerRenderer>
          </SnackbarProvider>
        </Router>
      </StoreProvider>
    </I18nProvider>
  </React.StrictMode>
);

export default App;
