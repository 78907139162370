import React, { useCallback, useEffect, useState } from "react";
import useFetchCustomersList from "hooks/useFetchCustomersList";
import { Button, Checkbox, Icon, Modal, Radio, TextInput } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { sortCustomersByName } from "utils/sort";
import { CustomerModel, DocumentModel } from "types/documentationApi.types";
import PermissionsConfirmationPopup from "./PermissionsConfirmationPopup";

import styles from "../styles.module.css";

interface PermissionsPopupProps {
  isOpen: boolean;
  fileToUpload: File | null;
  fileToEdit: DocumentModel | null;
  setIsOpen: (isOpen: boolean) => void;
  resetFile: () => void;
  resetEditFile: () => void;
}

const PermissionsPopup = ({
  isOpen,
  fileToUpload = null,
  fileToEdit = null,
  setIsOpen,
  resetFile,
  resetEditFile,
}: PermissionsPopupProps) => {
  const { t } = useTranslation("documentsPage");
  const { list: customersList } = useFetchCustomersList();
  const [isAllCustomersRadioSelected, setIsAllCustomersRadioSelected] = useState(false);
  const [customersLeft, setCustomersLeft] = useState<CustomerModel[]>([]);
  const [customersRight, setCustomersRight] = useState<CustomerModel[]>([]);
  const [checkedCustomersLeft, setCheckedCustomersLeft] = useState<number[]>([]);
  const [checkedCustomersRight, setCheckedCustomersRight] = useState<number[]>([]);
  const [customersFilter, setCustomersFilter] = useState<string>("");
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState<boolean>(false);

  const resetPopupState = useCallback(() => {
    setIsAllCustomersRadioSelected(false);
    setCustomersLeft([]);
    setCustomersRight([]);
    setCheckedCustomersLeft([]);
    setCheckedCustomersRight([]);
  }, []);

  const handleSwitchToSelectedCustomers = useCallback(() => setIsAllCustomersRadioSelected(false), []);
  const handleSwitchToAllCustomers = useCallback(() => setIsAllCustomersRadioSelected(true), []);

  const handleLeftCustomerClick = (customerErpId: number) => {
    checkedCustomersLeft.includes(customerErpId)
      ? setCheckedCustomersLeft([...checkedCustomersLeft.filter((id) => id !== customerErpId)])
      : setCheckedCustomersLeft([...checkedCustomersLeft, customerErpId]);
  };
  const handleRightCustomerClick = (customerErpId: number) => {
    checkedCustomersRight.includes(customerErpId)
      ? setCheckedCustomersRight([...checkedCustomersRight.filter((id) => id !== customerErpId)])
      : setCheckedCustomersRight([...checkedCustomersRight, customerErpId]);
  };

  const handleMoveRightAllClick = () => {
    setCustomersLeft([]);
    setCustomersRight([...customersRight, ...customersLeft]);
    setCheckedCustomersLeft([]);
  };
  const handleMoveLeftAllClick = () => {
    setCustomersRight([]);
    setCustomersLeft([...customersLeft, ...customersRight]);
    setCheckedCustomersRight([]);
  };
  const handleMoveRightClick = () => {
    setCustomersRight([
      ...customersRight,
      ...customersList.filter((customer) => checkedCustomersLeft.includes(customer.customerErpId)),
    ]);
    setCustomersLeft([...customersLeft.filter((customer) => !checkedCustomersLeft.includes(customer.customerErpId))]);
    setCheckedCustomersLeft([]);
  };

  const handleMoveLeftClick = () => {
    setCustomersLeft([
      ...customersLeft,
      ...customersList.filter((customer) => checkedCustomersRight.includes(customer.customerErpId)),
    ]);
    setCustomersRight([
      ...customersRight.filter((customer) => !checkedCustomersRight.includes(customer.customerErpId)),
    ]);
    setCheckedCustomersRight([]);
  };

  const handleCustomersFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCustomersFilter(event.target.value);

  const handleCloseModalBtnClick = () => {
    resetFile();
    resetPopupState();
    setIsOpen(false);
  };

  const handleUploadBtnClick = () => {
    setIsOpenConfirmationPopup(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (customersList.length) {
      const fileToEditInitialCustomerIds =
        fileToEdit?.customerPermissions.map((customer) => Number(customer.customerErpId)) || [];

      setCustomersLeft(
        fileToEdit
          ? customersList.filter((customer) => !fileToEditInitialCustomerIds.includes(customer.customerErpId))
          : customersList
      );
      setCustomersRight(fileToEdit ? fileToEdit.customerPermissions : []);
    }
  }, [customersList, fileToEdit, fileToUpload]);

  return (
    <>
      <Modal
        title={t("permissionsPopupTitle")}
        open={isOpen}
        controls={
          <>
            <Button type="secondary" onClick={handleCloseModalBtnClick} data-testid="permissionsPopupCloseBtn">
              {t("common:Cancel")}
            </Button>
            <Button onClick={handleUploadBtnClick} data-testid="permissionsPopupConfirmBtn">
              {fileToEdit ? t("common:Save") : t("common:Upload")}
            </Button>
          </>
        }
        onCloseBtnClick={handleCloseModalBtnClick}
        className={styles.docPermissionsPopup}
        data-testid="permissionsPopup"
      >
        <div className={styles.docPermissionsPopupSubtitle}>
          {t("permissionsPopupSubtitle")}
          <b>{fileToUpload ? fileToUpload.name : fileToEdit?.documentName}</b>
        </div>
        <div className={styles.docPermissionsPopupRadios}>
          <span>{t("permissionsPopupMsg")}</span>
          <Radio
            size="small"
            checked={!isAllCustomersRadioSelected}
            onChange={handleSwitchToSelectedCustomers}
            data-testid="selectedCustomersRadio"
          />
          <span onClick={handleSwitchToSelectedCustomers} className={styles.docPermissionsPopupRadioLabel}>
            {t("permissionsPopupSelectedCustomersRadio")}
          </span>
          <Radio
            size="small"
            checked={isAllCustomersRadioSelected}
            onChange={handleSwitchToAllCustomers}
            data-testid="allCustomersRadio"
          />
          <span onClick={handleSwitchToAllCustomers} className={styles.docPermissionsPopupRadioLabel}>
            {t("permissionsPopupAllCustomersRadio")}
          </span>
        </div>
        {!isAllCustomersRadioSelected && (
          <>
            <TextInput
              data-testid="permissionsFilter-customerName"
              className={styles.customersFilterInput}
              placeholder={t("customerNameFilterPlaceholder")}
              value={customersFilter}
              onChange={handleCustomersFilterChange}
            />
            <div className={styles.docPermissionsPopupCustomersSelection}>
              <div className={styles.docPermissionPopupCustomersLeft}>
                <span className={styles.docPermissionPopupCustomersLabel}>
                  {t("permissionsPopupWithoutPermissionLabel")}
                </span>
                <div className={styles.docPermissionPopupCustomersList}>
                  {sortCustomersByName(customersLeft)
                    .filter((customer) => customer.customerName.toLowerCase().includes(customersFilter.toLowerCase()))
                    .map((customer: CustomerModel) => (
                      <div
                        key={customer.key}
                        className={styles.docPermissionPopupCustomerRow}
                        onClick={() => handleLeftCustomerClick(customer.customerErpId)}
                        data-testid="customerLeft"
                      >
                        <Checkbox size="small" checked={checkedCustomersLeft.includes(customer.customerErpId)} />
                        <span>{customer.customerName}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.docPermissionPopupCustomersActions}>
                <Button type="tertiary" size="small" onClick={handleMoveRightAllClick} data-testid="moveRightAllBtn">
                  <Icon height={12} icon="two-arrows-right-small" />
                </Button>
                <Button type="tertiary" size="small" onClick={handleMoveRightClick} data-testid="moveRightBtn">
                  <Icon height={20} icon="arrow-right-small" />
                </Button>
                <Button type="tertiary" size="small" onClick={handleMoveLeftClick} data-testid="moveLeftBtn">
                  <Icon height={20} icon="arrow-left-small" />
                </Button>
                <Button type="tertiary" size="small" onClick={handleMoveLeftAllClick} data-testid="moveLeftAllBtn">
                  <Icon height={12} icon="two-arrows-left-small" />
                </Button>
              </div>
              <div className={styles.docPermissionPopupCustomersRight} data-testid="customersRightColumn">
                <span className={styles.docPermissionPopupCustomersLabel}>
                  {t("permissionsPopupWithPermissionLabel")}
                </span>
                <div className={styles.docPermissionPopupCustomersList}>
                  {sortCustomersByName(customersRight).map((customer: CustomerModel) => (
                    <div
                      key={customer.key}
                      className={styles.docPermissionPopupCustomerRow}
                      onClick={() => handleRightCustomerClick(customer.customerErpId)}
                      data-testid="customerRight"
                    >
                      <Checkbox size="small" checked={checkedCustomersRight.includes(customer.customerErpId)} />
                      <span>{customer.customerName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
      <PermissionsConfirmationPopup
        isOpen={isOpenConfirmationPopup}
        fileToUpload={fileToUpload}
        fileToEdit={fileToEdit}
        isAllCustomers={isAllCustomersRadioSelected}
        customersList={isAllCustomersRadioSelected ? customersList : customersRight}
        setIsOpen={setIsOpenConfirmationPopup}
        setIsPermissionsPopupOpen={setIsOpen}
        resetFile={resetFile}
        resetEditFile={resetEditFile}
        resetPermissionsPopupState={resetPopupState}
      />
    </>
  );
};
export default PermissionsPopup;
