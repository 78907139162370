import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Spinner, Table, TextInput, Tooltip } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import {
  setDocumentsListCurrentPageAC,
  setDocumentsListFiltersAC,
  setDocumentsListPerPageAC,
  setDocumentsListSortFieldAC,
} from "store/documents/documents.actions";
import PaginationBox from "containers/PaginationBox";
import { documentsSelectors, documentsThunks } from "store/documents";
import { DocumentModel } from "types/documentationApi.types";
import { generateDocumentDownloadUrl } from "utils/helpers";
import { initState } from "store/documents/documents.reducer";
import { previewDocumentFormats } from "components/DocumentPreview/DocumentPreview";
import CardFallback from "containers/CardFallback";

import styles from "./styles.module.css";

const CustomerView = () => {
  const { t } = useTranslation("documentsPage");
  const dispatch = useDispatch();
  const { filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    documentsSelectors.getSlice
  );
  const { list, pageCount } = useSelector(documentsSelectors.getCustomerDocumentsTable);
  const [filterDraft, setFilterDraft] = useState(initState.filters);

  useEffect(() => {
    dispatch(setDocumentsListFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterDraft({ ...filterDraft, documentName: event.target.value });
    dispatch(setDocumentsListCurrentPageAC(1));
  };

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(documentsThunks.getDocumentsList());
  }, [thunkDispatch]);

  const columnsConfig = useMemo(
    () => [
      {
        key: "",
        dataIndex: "",
        title: t(""),
        sortDisabled: true,
        width: "64px",
        render: (_: undefined, item: DocumentModel) => (
          <div className={styles.documentActionBtns}>
            {previewDocumentFormats.includes(item.documentName.split(".").pop() as string) && (
              <Tooltip title={t("common:Preview")}>
                <Link to={`/documents/preview/${item.documentId}`}>
                  <Button type="tertiary" leftIcon="preview-doc" data-testid={`previewBtn-${item.documentId}`} />
                </Link>
              </Tooltip>
            )}
            <Tooltip title={t("common:Download")}>
              <a href={generateDocumentDownloadUrl(item.documentId)} download={item.documentName}>
                <Button type="tertiary" leftIcon="download" />
              </a>
            </Tooltip>
          </div>
        ),
      },
      {
        key: "documentName",
        dataIndex: "documentName",
        title: t("documentsTable.documentName"),
        render: (documentName: string, item: DocumentModel) => (
          <a
            href={generateDocumentDownloadUrl(item.documentId)}
            download={item.documentName}
            className={styles.documentNameLink}
          >
            <span>{documentName}</span>
          </a>
        ),
      },
      { key: "uploadedDate", dataIndex: "uploadedDate", title: t("documentsTable.uploadedDate"), width: "120px" },
    ],
    [t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setDocumentsListSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setDocumentsListPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setDocumentsListCurrentPageAC(value)), [dispatch]);

  return (
    <Card className={styles.documentsListCard} data-testid="customersDocumentsListTable">
      {requestStatus === RequestStatus.PENDING && <Spinner className={styles.spinner} show />}
      {requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.documentListContent}>
          <TextInput
            data-testid="customerDocsFilter-documentName"
            className={styles.documentsFilterInput}
            placeholder={t("documentNameFilterPlaceholder")}
            value={filters.documentName}
            onChange={handleNameFilterChange}
          />
          <div className={styles.documentsListTableBlock}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
            className={styles.paginationBox}
          />
        </div>
      )}
      {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </Card>
  );
};

export default CustomerView;
