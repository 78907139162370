import * as types from "./customers.types";

export const customersListRequestAC: types.CustomersListRequestAC = () => ({
  type: types.CUSTOMERS_LIST_REQUEST,
});

export const customersListRequestSuccessAC: types.CustomersListRequestSuccessAC = (payload) => ({
  type: types.CUSTOMERS_LIST_REQUEST_SUCCESS,
  payload,
});

export const customersListRequestFailureAC: types.CustomersListRequestFailureAC = () => ({
  type: types.CUSTOMERS_LIST_REQUEST_FAILURE,
});
