import { AxiosResponse } from "axios";
import { documentationApiClient } from "./clients";
import { DocumentsResponse, DocumentResponse } from "types/documentationApi.types";

export const getDocuments = () =>
  documentationApiClient.request<DocumentsResponse>({
    method: "GET",
    url: "/v1/documents",
  });

export const getDocument = (documentId: number) =>
  documentationApiClient.request<DocumentResponse>({
    method: "GET",
    url: `/v1/documents/${documentId}`,
  });

export const uploadDocument = (formData: FormData) =>
  documentationApiClient.request<DocumentResponse>({
    method: "POST",
    url: "/v1/documents",
    data: formData,
  });

export const updateDocumentPermissions = (documentId: number, customerErpIds: number[]) =>
  documentationApiClient.request<AxiosResponse>({
    method: "POST",
    url: `/v1/documents/${documentId}/permissions`,
    data: {
      customerPermissions: { customerErpIds },
    },
  });

export const deleteDocument = (documentId: number) =>
  documentationApiClient.request<AxiosResponse>({
    method: "DELETE",
    url: `/v1/documents/${documentId}`,
  });

export const downloadDocument = (documentId: number) =>
  documentationApiClient.request<AxiosResponse>({
    method: "GET",
    url: `/v1/documents/${documentId}/download`,
  });
