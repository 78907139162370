import { SortOrder } from "types/common.types";

export const makeUniversalSort =
  (normalizer: (item: any) => string | number | boolean) => (sortWay: SortOrder) => (a: any, b: any) => {
    const flag = sortWay === "asc" ? -1 : 1;
    try {
      return normalizer(a) >= normalizer(b) ? flag * -1 : flag;
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      return 0;
    }
  };

export const PAGINATION_STEPS = [10, 20, 50, 100];

export const getPaginationSlice = (list: any[], page: number, perPage: number) =>
  list.slice((page - 1) * perPage, page * perPage);
