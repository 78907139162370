// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_documentPreviewHeader__hhVfq {\n  position: relative;\n  z-index: 2;\n  display: flex;\n  align-items: center;\n  padding-bottom: 16px;\n}\n\n.styles_pdfPrevPageButton__uAFbt,\n.styles_pdfCurrentPage__C8fZ2 {\n  margin-right: 8px;\n}\n\n.styles_pdfNextPageButton__ucSDW,\n.styles_pdfZoomBtn__OxTOr {\n  margin-right: 16px;\n}\n\n.styles_pdfPage__k27AN canvas {\n  margin: auto;\n}\n\n.styles_documentPreviewHeaderTitle__Mp23X {\n  margin-right: auto;\n  margin-left: 16px;\n}\n\n.styles_documentPreviewFallback__-nihQ {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 32px;\n}\n\n.styles_documentPreviewFallback__-nihQ h2 {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentPreview/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".documentPreviewHeader {\n  position: relative;\n  z-index: 2;\n  display: flex;\n  align-items: center;\n  padding-bottom: 16px;\n}\n\n.pdfPrevPageButton,\n.pdfCurrentPage {\n  margin-right: 8px;\n}\n\n.pdfNextPageButton,\n.pdfZoomBtn {\n  margin-right: 16px;\n}\n\n.pdfPage canvas {\n  margin: auto;\n}\n\n.documentPreviewHeaderTitle {\n  margin-right: auto;\n  margin-left: 16px;\n}\n\n.documentPreviewFallback {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 32px;\n}\n\n.documentPreviewFallback h2 {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentPreviewHeader": "styles_documentPreviewHeader__hhVfq",
	"pdfPrevPageButton": "styles_pdfPrevPageButton__uAFbt",
	"pdfCurrentPage": "styles_pdfCurrentPage__C8fZ2",
	"pdfNextPageButton": "styles_pdfNextPageButton__ucSDW",
	"pdfZoomBtn": "styles_pdfZoomBtn__OxTOr",
	"pdfPage": "styles_pdfPage__k27AN",
	"documentPreviewHeaderTitle": "styles_documentPreviewHeaderTitle__Mp23X",
	"documentPreviewFallback": "styles_documentPreviewFallback__-nihQ"
};
export default ___CSS_LOADER_EXPORT___;
