import { RequestStatus } from "types/common.types";
import {
  CUSTOMERS_LIST_REQUEST,
  CUSTOMERS_LIST_REQUEST_FAILURE,
  CUSTOMERS_LIST_REQUEST_SUCCESS,
  CustomersListActions,
  CustomersListStateSlice,
} from "./customers.types";

export const initState: CustomersListStateSlice = {
  list: [],
  requestStatus: RequestStatus.UNCALLED,
};

export default function customersReducer(
  state: CustomersListStateSlice = initState,
  action: CustomersListActions
): CustomersListStateSlice {
  switch (action.type) {
    case CUSTOMERS_LIST_REQUEST: {
      return {
        ...state,
        requestStatus: RequestStatus.PENDING,
      };
    }
    case CUSTOMERS_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        requestStatus: RequestStatus.SUCCESS,
      };
    }
    case CUSTOMERS_LIST_REQUEST_FAILURE: {
      return {
        ...initState,
        requestStatus: RequestStatus.FAILURE,
      };
    }

    default:
      return state;
  }
}
