import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as documentsApi from "api/documentation.api";
import { documentsSelectors } from "store/documents";
import { DocumentModel } from "types/documentationApi.types";

const placeholderDocument = {
  documentId: 0,
  documentName: "",
  customerPermissions: [],
  uploadedDate: "",
};

const useFetchDocument = (documentId: number) => {
  const documentsList = useSelector(documentsSelectors.getDocumentsList);
  const [document, setDocument] = useState<DocumentModel>(
    documentsList.find((doc) => doc.documentId === documentId) || placeholderDocument
  );

  useEffect(() => {
    async function fetchDocument() {
      try {
        const { data } = await documentsApi.getDocument(documentId);
        setDocument(data.document);
      } catch (e) {
        console.error(e);
      }
    }

    if (document.documentId === 0) fetchDocument();
  }, [document.documentId, documentId]);

  return document;
};

export default useFetchDocument;
