import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Spinner, Table, TextInput, Tooltip } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { MimeTypes, RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import {
  setDocumentsListCurrentPageAC,
  setDocumentsListFiltersAC,
  setDocumentsListPerPageAC,
  setDocumentsListSortFieldAC,
} from "store/documents/documents.actions";
import PaginationBox from "containers/PaginationBox";
import { documentsSelectors, documentsThunks } from "store/documents";
import { customersThunks } from "store/customers";
import { initState } from "store/documents/documents.reducer";
import { DocumentModel } from "types/documentationApi.types";
import { previewDocumentFormats } from "components/DocumentPreview/DocumentPreview";
import { generateDocumentDownloadUrl } from "utils/helpers";
import CardFallback from "containers/CardFallback";
import NotAllowedFormatPopup from "./popups/NotAllowedFormatPopup";
import ReplaceDocumentPopup from "./popups/ReplaceDocumentPopup";
import PermissionsPopup from "./popups/PermissionsPopup";
import DeleteDocumentPopup from "./popups/DeleteDocumentPopup";

import styles from "./styles.module.css";

const MAX_COLUMN_CUSTOMER_NAMES_LENGTH = 200;
const MAX_TOOLTIP_CUSTOMER_NAMES_LENGTH = 500;

const AdminView = () => {
  const { t } = useTranslation("documentsPage");
  const dispatch = useDispatch();
  const { filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    documentsSelectors.getSlice
  );
  const { list, pageCount } = useSelector(documentsSelectors.getCustomerDocumentsTable);
  const [filterDraft, setFilterDraft] = useState(initState.filters);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [replacementFile, setReplacementFile] = useState<File | null>(null);
  const [fileToEdit, setFileToEdit] = useState<DocumentModel | null>(null);
  const [isReplaceDocumentPopupOpen, setIsReplaceDocumentPopupOpen] = useState(false);
  const [isPermissionsPopupOpen, setIsPermissionsPopupOpen] = useState(false);
  const [isDeleteDocumentPopupOpen, setIsDeleteDocumentPopupOpen] = useState(false);

  const handleReplaceFileChange = useCallback((e: ChangeEvent<HTMLInputElement>, document: DocumentModel) => {
    if (e.target.files) {
      setReplacementFile(e.target.files[0]);
      setFileToEdit(document);
    }
  }, []);

  const handleEditBtnClick = useCallback(
    (document: DocumentModel) => {
      setFileToEdit(document);
      setIsPermissionsPopupOpen(true);
    },
    [setFileToEdit, setIsPermissionsPopupOpen]
  );

  const handleDeleteBtnClick = useCallback((document: DocumentModel) => {
    setFileToEdit(document);
    setIsDeleteDocumentPopupOpen(true);
  }, []);

  const resetEditFile = useCallback(() => setFileToEdit(null), []);
  const resetFile = useCallback(() => {
    fileInputRef.current && (fileInputRef.current.value = "");

    const fileReplaceInput = document.getElementById(`file-replace-${fileToEdit?.documentId}`);
    fileReplaceInput && ((fileReplaceInput as HTMLInputElement).value = "");

    setFile(null);
    setReplacementFile(null);
    setFileToEdit(null);
  }, [fileToEdit]);

  const columnsConfig = useMemo(
    () => [
      {
        key: "docViewActions",
        dataIndex: "",
        title: t(""),
        sortDisabled: true,
        width: "64px",
        render: (_: undefined, item: DocumentModel) => (
          <div className={styles.documentActionBtns}>
            {previewDocumentFormats.includes(item.documentName.split(".").pop() as string) && (
              <Tooltip title={t("common:Preview")}>
                <Link to={`/documents/preview/${item.documentId}`}>
                  <Button type="tertiary" leftIcon="preview-doc" />
                </Link>
              </Tooltip>
            )}
            <Tooltip title={t("common:Download")}>
              <a href={generateDocumentDownloadUrl(item.documentId)} download={item.documentName}>
                <Button type="tertiary" leftIcon="download" />
              </a>
            </Tooltip>
          </div>
        ),
      },
      {
        key: "documentName",
        dataIndex: "documentName",
        title: t("documentsTable.documentName"),
        width: "20%",
        render: (documentName: string, item: DocumentModel) => (
          <a
            href={generateDocumentDownloadUrl(item.documentId)}
            download={item.documentName}
            className={styles.documentNameLink}
          >
            <span>{documentName}</span>
          </a>
        ),
      },
      {
        key: "customerNames",
        dataIndex: "customerNames",
        title: t("documentsTable.customerNames"),
        render: (customerNames: string) =>
          customerNames.length > MAX_COLUMN_CUSTOMER_NAMES_LENGTH ? (
            <Tooltip title={`${customerNames.substring(0, MAX_TOOLTIP_CUSTOMER_NAMES_LENGTH)}...`}>
              <span>{`${customerNames.substring(0, MAX_COLUMN_CUSTOMER_NAMES_LENGTH)}...`}</span>
            </Tooltip>
          ) : (
            customerNames
          ),
      },
      { key: "uploadedDate", dataIndex: "uploadedDate", title: t("documentsTable.uploadedDate"), width: "120px" },
      {
        key: "docActions",
        dataIndex: "",
        title: t(""),
        sortDisabled: true,
        width: "96px",
        render: (_: undefined, document: DocumentModel) => (
          <div className={styles.documentActionBtns}>
            <Tooltip title={t("common:Replace")}>
              <div className={styles.replaceBtnBlock}>
                <label htmlFor={`file-replace-${document.documentId}`} className={styles.customReplaceFileInputBtn}>
                  <Icon icon="doc-transfer" />
                </label>
                <input
                  id={`file-replace-${document.documentId}`}
                  type="file"
                  onChange={(e) => handleReplaceFileChange(e, document)}
                  accept={Object.values(MimeTypes).join(",")}
                  data-testid={`fileReplace-${document.documentId}`}
                />
              </div>
            </Tooltip>
            <Tooltip title={t("common:Edit")}>
              <Button
                type="tertiary"
                leftIcon="pen"
                onClick={() => handleEditBtnClick(document)}
                data-testid={`editDocument-${document.documentId}`}
              />
            </Tooltip>
            <Tooltip title={t("common:Delete")}>
              <Button
                type="tertiary"
                leftIcon="trash-bin"
                onClick={() => handleDeleteBtnClick(document)}
                data-testid={`deleteDocument-${document.documentId}`}
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    [handleReplaceFileChange, handleEditBtnClick, handleDeleteBtnClick, t]
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const isAllowedFileType = useMemo(
    () =>
      file || replacementFile
        ? Object.values(MimeTypes).includes((file?.type as MimeTypes) || (replacementFile?.type as MimeTypes))
        : true,
    [file, replacementFile]
  );

  useEffect(() => {
    if (!!replacementFile && isAllowedFileType) setIsReplaceDocumentPopupOpen(true);
  }, [replacementFile, isAllowedFileType]);

  useEffect(() => {
    if (!!file && isAllowedFileType) setIsPermissionsPopupOpen(true);
  }, [file, isAllowedFileType]);

  // table filtering, sorting, pagination and reloading logic
  useEffect(() => {
    dispatch(setDocumentsListFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, documentName: value });
    dispatch(setDocumentsListCurrentPageAC(1));
  };
  const handleCustomerNamesFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, customerNames: value });
    dispatch(setDocumentsListCurrentPageAC(1));
  };

  const handleSortFieldChange = useCallback((field) => dispatch(setDocumentsListSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setDocumentsListPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setDocumentsListCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(documentsThunks.getDocumentsList());
    thunkDispatch(customersThunks.getCustomersList());
  }, [thunkDispatch]);

  return (
    <>
      <Card className={styles.adminDocumentsListCard} data-testid="adminDocumentsListTable">
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.documentListContent}>
            <div className={styles.documentListHeader}>
              <label htmlFor="file-upload" className={styles.customFileInputBtn}>
                {t("uploadDocument")}
              </label>
              <input
                id="file-upload"
                ref={fileInputRef}
                type="file"
                onChange={handleFileChange}
                accept={Object.values(MimeTypes).join(",")}
                data-testid="fileUpload"
              />
              <TextInput
                data-testid="adminDocsFilter-documentName"
                className={styles.adminDocumentsFilterInput}
                placeholder={t("documentNameFilterPlaceholder")}
                value={filters.documentName}
                onChange={(e) => handleNameFilterChange(e.target.value)}
              />
              <TextInput
                data-testid="adminDocsFilter-customerNames"
                className={styles.adminDocumentsFilterInput}
                placeholder={t("customerNamesFilterPlaceholder")}
                value={filters.customerNames}
                onChange={(e) => handleCustomerNamesFilterChange(e.target.value)}
              />
            </div>
            <div className={styles.documentsListTableBlock}>
              <Table
                dataSource={list}
                columns={columnsConfig}
                sortField={sortField}
                sortOrder={sortOrder}
                onSortFieldChange={handleSortFieldChange}
              />
            </div>
            <PaginationBox
              perPage={perPage}
              currentPage={currentPage}
              pageCount={pageCount}
              onPerPageChange={handlePerPageChange}
              onChangePage={handlePageChange}
              className={styles.paginationBox}
            />
          </div>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </Card>
      <NotAllowedFormatPopup isOpen={!isAllowedFileType} resetFile={resetFile} />
      <ReplaceDocumentPopup
        isOpen={isReplaceDocumentPopupOpen}
        setIsOpen={setIsReplaceDocumentPopupOpen}
        fileToReplace={fileToEdit}
        replacementFile={replacementFile}
        resetFile={resetFile}
      />
      <PermissionsPopup
        isOpen={isPermissionsPopupOpen}
        setIsOpen={setIsPermissionsPopupOpen}
        fileToUpload={file}
        fileToEdit={fileToEdit}
        resetFile={resetFile}
        resetEditFile={resetEditFile}
      />
      <DeleteDocumentPopup
        isOpen={isDeleteDocumentPopupOpen}
        setIsOpen={setIsDeleteDocumentPopupOpen}
        fileToDelete={fileToEdit}
        resetEditFile={resetEditFile}
      />
    </>
  );
};

export default AdminView;
