import { RootState } from "../index";
import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { DocumentsListStateSlice } from "./documents.types";
import { DocumentModel } from "types/documentationApi.types";

export const getSlice = (state: RootState) => state.documents;
export const getDocumentsList = (state: RootState) => state.documents.list;

const makeDocumentNameSortNormalizer = (item: DocumentModel) => item.documentName;
const makeCustomerNamesSortNormalizer = (item: DocumentModel) => item.customerNames || "";
const makeUploadedDateSortNormalizer = (item: DocumentModel) => item.uploadedDate;

const documentsComposer = ({ filters, list, sortField, sortOrder, currentPage, perPage }: DocumentsListStateSlice) => {
  let _list: DocumentModel[] = [...list];

  _list = [..._list].map((document) => ({
    ...document,
    // convert customers array to string to show in table and use for filtering
    customerNames: Array.isArray(document.customerPermissions)
      ? document.customerPermissions.map((customer) => customer.customerName).join(", ")
      : document.customerPermissions,
  }));

  if (filters.documentName) {
    _list = _list.filter((item) => item.documentName.toLowerCase().includes(filters.documentName.toLowerCase()));
  }

  if (filters.customerNames) {
    _list = _list.filter((item) => item.customerNames?.toLowerCase().includes(filters.customerNames.toLowerCase()));
  }

  const sortHandler: any = {
    documentName: makeUniversalSort(makeDocumentNameSortNormalizer),
    customerNames: makeUniversalSort(makeCustomerNamesSortNormalizer),
    uploadedDate: makeUniversalSort(makeUploadedDateSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getCustomerDocumentsTable = (state: RootState) => {
  const documentsList = getSlice(state);
  return documentsComposer(documentsList);
};

export const getAdminDocumentsTable = (state: RootState) => {
  const documentsList = getSlice(state);
  return documentsComposer(documentsList);
};
