import React, { useState } from "react";
import { Button, Icon, Modal, Spinner } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { CustomerModel, DocumentModel } from "types/documentationApi.types";
import * as documentsApi from "api/documentation.api";
import { useDispatch } from "react-redux";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";
import { documentsActions } from "store/documents";
import { sortCustomersByName } from "utils/sort";

import styles from "../styles.module.css";

interface PermissionsConfirmationPopupProps {
  isOpen: boolean;
  fileToUpload: File | null;
  fileToEdit: DocumentModel | null;
  isAllCustomers: boolean;
  customersList: CustomerModel[];
  setIsOpen: (isOpen: boolean) => void;
  setIsPermissionsPopupOpen: (isOpen: boolean) => void;
  resetFile: () => void;
  resetEditFile: () => void;
  resetPermissionsPopupState: () => void;
}

const PermissionsConfirmationPopup = ({
  isOpen,
  fileToUpload = null,
  fileToEdit = null,
  isAllCustomers,
  customersList,
  setIsOpen,
  setIsPermissionsPopupOpen,
  resetFile,
  resetEditFile,
  resetPermissionsPopupState,
}: PermissionsConfirmationPopupProps) => {
  const { t } = useTranslation("documentsPage");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelBtnClick = () => {
    setIsOpen(false);
    setIsPermissionsPopupOpen(true);
  };

  const handleUploadBtnClick = async () => {
    setIsLoading(true);
    if (fileToUpload) {
      try {
        const formData = new FormData();

        formData.append("file", fileToUpload);
        formData.append("documentName", fileToUpload.name);
        formData.append("contentType", fileToUpload.type);
        formData.append(
          "customerPermissions",
          JSON.stringify({
            customerErpIds: customersList.map((customer) => customer.customerErpId),
          })
        );

        const { data } = await documentsApi.uploadDocument(formData);
        setIsOpen(false);
        resetFile();
        resetPermissionsPopupState();
        dispatch(documentsActions.addDocumentToListAC(data.document));
      } catch (apiErrorMessage) {
        console.error(apiErrorMessage, "UPLOAD FILE REQUEST FAILURE");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:uploadFileRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
      }
    } else if (fileToEdit) {
      try {
        await documentsApi.updateDocumentPermissions(
          fileToEdit.documentId,
          customersList.map((customer) => customer.customerErpId)
        );
        setIsOpen(false);
        resetEditFile();
        resetPermissionsPopupState();
        dispatch(
          documentsActions.updateDocumentPermissionsAC({
            documentId: fileToEdit.documentId,
            customerPermissions: customersList,
          })
        );
      } catch (apiErrorMessage) {
        console.error(apiErrorMessage, "PERMISSIONS UPDATE REQUEST FAILURE");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:permissionsUpdateRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title={t("permissionsConfirmationPopupTitle")}
      open={isOpen}
      controls={
        <>
          <Button
            type="secondary"
            onClick={handleCancelBtnClick}
            disabled={isLoading}
            data-testid="permissionsConfirmationPopupCancelBtn"
          >
            {t("common:Cancel")}
          </Button>
          <Button
            onClick={handleUploadBtnClick}
            loading={isLoading}
            disabled={isLoading}
            data-testid="permissionsConfirmationPopupConfirmBtn"
          >
            {fileToEdit ? t("common:Save") : t("common:Upload")}
          </Button>
        </>
      }
      onCloseBtnClick={handleCancelBtnClick}
      data-testid="permissionsConfirmationPopup"
    >
      <Spinner show={isLoading} />
      {isAllCustomers ? (
        <>
          <div>
            {t("permissionsConfirmationAllCustomersBegin")}
            <b>{fileToUpload ? fileToUpload.name : fileToEdit?.documentName}</b>
            {t("permissionsConfirmationAllCustomersMiddle")}
            <b>{t("permissionsConfirmationAllCustomersEnd")}</b>
          </div>

          <div className={styles.permissionsConfirmationWarningBlock}>
            <Icon icon="info-attention" color="popRed" height={32} />
            <div>
              <b>{t("permissionsConfirmationAllCustomersWarningBegin")}</b>
              {t("permissionsConfirmationAllCustomersWarningEnd")}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {customersList.length ? (
              <>
                {t("permissionsConfirmationSelectedCustomersBegin")}
                <b>{fileToUpload ? fileToUpload.name : fileToEdit?.documentName}</b>
                {t("permissionsConfirmationSelectedCustomersEnd")}
              </>
            ) : (
              <>
                {t("permissionsConfirmationNoCustomersBegin")}
                <b>{fileToUpload?.name}</b>
                {t("permissionsConfirmationNoCustomersEnd")}
              </>
            )}
          </div>
          {customersList.length ? (
            <div className={styles.permissionConfirmationCustomersList}>
              {sortCustomersByName(customersList).map((customer) => (
                <div key={customer.customerErpId}>- {customer.customerName}</div>
              ))}
            </div>
          ) : null}
        </>
      )}
    </Modal>
  );
};
export default PermissionsConfirmationPopup;
