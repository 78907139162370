import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as documents } from "./documents";
import { reducer as customers } from "./customers";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  documents,
  customers,
  notification,
});

export default rootReducer;
