import React from "react";
import { Button, Modal } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";

interface NotAllowedFormatPopupProps {
  isOpen: boolean;
  resetFile: () => void;
}

const NotAllowedFormatPopup = ({ isOpen, resetFile }: NotAllowedFormatPopupProps) => {
  const { t } = useTranslation("documentsPage");

  return (
    <Modal
      title={t("notAllowedFormatPopupTitle")}
      open={isOpen}
      controls={<Button onClick={resetFile}>{t("common:Cancel")}</Button>}
      onCloseBtnClick={resetFile}
    >
      {t("notAllowedFormatPopupMsg")}
    </Modal>
  );
};
export default NotAllowedFormatPopup;
