import React from "react";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  title: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <header className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
    </header>
  );
};

export default PageHeader;
