import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { documentsSelectors, documentsThunks } from "store/documents";

const useFetchDocumentsList = () => {
  const thunkDispatch = useThunkDispatch();

  useEffect(() => {
    thunkDispatch(documentsThunks.getDocumentsList());
  }, [thunkDispatch]);

  return useSelector(documentsSelectors.getSlice);
};

export default useFetchDocumentsList;
