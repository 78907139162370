import { AppThunk } from "store";
import { v4 as uuidv4 } from "uuid";
import * as customersApi from "api/customers.api";
import * as customersActions from "./customers.actions";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";

export const getCustomersList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(customersActions.customersListRequestAC());
    const { data } = await customersApi.getCustomers();

    // assign a key to every customer to handle customer lists correctly
    if (data.customers) data.customers = data.customers.map((customer) => ({ ...customer, key: uuidv4() }));

    dispatch(customersActions.customersListRequestSuccessAC(data.customers || []));
  } catch (apiErrorMessage) {
    console.error(apiErrorMessage, "CUSTOMERS LIST REQUEST FAILURE");
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:customersListRequestFailure"),
        description: parseApiErrorMessage(apiErrorMessage),
      })
    );
    dispatch(customersActions.customersListRequestFailureAC());
  }
};
