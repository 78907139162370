import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";

const DocumentPreviewFallback = () => {
  const { t } = useTranslation("documentPreviewPage");
  return (
    <div className={styles.documentPreviewFallback}>
      <h2>{t("errorMsg")}</h2>
      <p>{t("errorDescr")}</p>
    </div>
  );
};

export default DocumentPreviewFallback;
