import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DocumentsPage from "pages/DocumentsPage";
import DocumentPreview from "components/DocumentPreview";

export const AppRouter: React.FC = () => (
  <Switch>
    <Route path="/documents" exact component={DocumentsPage} />
    <Route path="/documents/preview/:documentId" exact component={DocumentPreview} />
    <Route path="*">
      <Redirect to="/documents" />
    </Route>
  </Switch>
);

export default AppRouter;
