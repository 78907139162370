import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { customersSelectors, customersThunks } from "store/customers";

const useFetchCustomersList = () => {
  const thunkDispatch = useThunkDispatch();

  useEffect(() => {
    thunkDispatch(customersThunks.getCustomersList());
  }, [thunkDispatch]);

  return useSelector(customersSelectors.getSlice);
};

export default useFetchCustomersList;
