import config from "config";

export const generateDocumentDownloadUrl = (documentId: number) => {
  return [config.documentationApiUrl, `/v1/documents/${documentId}/download`].join("");
};

export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};
