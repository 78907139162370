import React, { useState } from "react";
import { Button, Modal, Spinner } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { DocumentModel } from "types/documentationApi.types";
import { useDispatch } from "react-redux";
import * as documentsApi from "api/documentation.api";
import { parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";
import { notificationErrorAC } from "store/notification/notification.actions";
import { documentsActions } from "store/documents";

interface ReplaceDocumentPopupProps {
  isOpen: boolean;
  fileToReplace: DocumentModel | null;
  replacementFile: File | null;
  setIsOpen: (isOpen: boolean) => void;
  resetFile: () => void;
}

const ReplaceDocumentPopup = ({
  isOpen,
  fileToReplace,
  replacementFile = null,
  setIsOpen,
  resetFile,
}: ReplaceDocumentPopupProps) => {
  const { t } = useTranslation("documentsPage");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseBtnClick = () => {
    resetFile();
    setIsOpen(false);
  };

  const handleReplaceBtnClick = async () => {
    setIsLoading(true);
    if (replacementFile && fileToReplace) {
      try {
        const formData = new FormData();

        formData.append("file", replacementFile);
        formData.append("documentName", replacementFile.name);
        formData.append("contentType", replacementFile.type);
        formData.append(
          "customerPermissions",
          JSON.stringify({
            customerErpIds: fileToReplace.customerPermissions.map((customer) => customer.customerErpId),
          })
        );

        const { data } = await documentsApi.uploadDocument(formData);
        await documentsApi.deleteDocument(fileToReplace.documentId);
        resetFile();
        setIsOpen(false);
        dispatch(
          documentsActions.replaceDocumentInListAC({
            oldDocumentId: fileToReplace.documentId,
            newDocument: data.document,
          })
        );
      } catch (apiErrorMessage) {
        console.error(apiErrorMessage, "REPLACE FILE REQUEST FAILURE");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:replaceFileRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={t("replaceDocumentPopupTitle")}
      open={isOpen}
      controls={
        <>
          <Button
            type="secondary"
            onClick={handleCloseBtnClick}
            disabled={isLoading}
            data-testid="replaceDocumentPopupCancelBtn"
          >
            {t("common:Cancel")}
          </Button>
          <Button
            onClick={handleReplaceBtnClick}
            loading={isLoading}
            disabled={isLoading}
            data-testid="replaceDocumentPopupReplaceBtn"
          >
            {t("common:Replace")}
          </Button>
        </>
      }
      onCloseBtnClick={handleCloseBtnClick}
      data-testid="replaceDocumentPopup"
    >
      <Spinner show={isLoading} />
      {t("replaceDocumentPopupMsgBegin")}
      <b>{fileToReplace?.documentName}</b>
      {t("replaceDocumentPopupMsgMiddle")}
      <b>{replacementFile?.name}</b>
      {t("replaceDocumentPopupMsgEnd")}
    </Modal>
  );
};
export default ReplaceDocumentPopup;
