import { RequestStatus } from "types/common.types";
import { CustomerModel } from "types/documentationApi.types";

export interface CustomersListStateSlice {
  readonly list: CustomerModel[];
  readonly requestStatus: RequestStatus;
}

export const CUSTOMERS_LIST_REQUEST = "CUSTOMERS_LIST_REQUEST";
export type CustomersListRequestAction = { type: typeof CUSTOMERS_LIST_REQUEST };
export type CustomersListRequestAC = () => CustomersListRequestAction;

export const CUSTOMERS_LIST_REQUEST_SUCCESS = "CUSTOMERS_LIST_REQUEST_SUCCESS";
export type CustomersListRequestSuccessAction = {
  type: typeof CUSTOMERS_LIST_REQUEST_SUCCESS;
  payload: CustomerModel[];
};
export type CustomersListRequestSuccessAC = (payload: CustomerModel[]) => CustomersListRequestSuccessAction;

export const CUSTOMERS_LIST_REQUEST_FAILURE = "CUSTOMERS_LIST_REQUEST_FAILURE";
export type CustomersListRequestFailureAction = { type: typeof CUSTOMERS_LIST_REQUEST_FAILURE };
export type CustomersListRequestFailureAC = () => CustomersListRequestFailureAction;

export type CustomersListActions =
  | CustomersListRequestAction
  | CustomersListRequestSuccessAction
  | CustomersListRequestFailureAction;
